import React, { useRef, useState, useEffect } from 'react';
import './ContactUs.css';
import emailjs from '@emailjs/browser';
import { useTranslation } from "react-i18next";
import { FaFacebookF, FaLinkedinIn, FaInstagram } from 'react-icons/fa';

const ContactUs = () => {
  const form = useRef();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  const sendEmail = (e) => {
    e.preventDefault();

    const userName = form.current.user_name.value;
    const userEmail = form.current.user_email.value;
    const userMessage = form.current.message.value;
    const regex = /^[\w-]+(.[\w-]+)*@([\w-]+.)+[a-zA-Z]{2,7}$/;

    if (!userName || !userEmail || !userMessage) {
      setErrorMessage(t(t('ERROR1')));
      return;
    }

    if (!regex.test(userEmail)) {
      setErrorMessage(t('Please enter a valid email address.'));
      return;
    }

    setErrorMessage("");

    emailjs
      .sendForm(
        'service_tqxzdt1',
        'template_1lef6zb',
        form.current, 'MS8wc2lCFJToJVqn0')
      .then((result) => {
        console.log(result);
        console.log("Viesti on lähetetty");
        setIsSubmitted(true);
        form.current.reset();
        setSuccessMessage(t('SUCCESS'));
      }, (error) => {
        console.log(error.text);
      });
  };

  const adjustTextareaHeight = (e) => {
    e.target.style.height = 'auto'; // Reset the height
    e.target.style.height = `${e.target.scrollHeight}px`; // Set the height to the scrollHeight
  };

  return (
    <div className="contact-container">
      <div className="text-section">
        <div className='some-section-mobile'>
          <h3 className='some-header'>{t("SOME")}</h3>
          <p className='some-description'>{t("SOME1")}</p>
          <div className="some-buttons">
            <a style={{marginRight: '2em;'}}>
              <FaFacebookF className="footerfab" onClick={() => window.open('https://www.facebook.com/profile.php?id=61551928770102', '_blank', 'noopener,noreferrer')}/>
            </a>
            <a>
              <FaInstagram className="footerfab" onClick={() => window.open('https://www.instagram.com/tinkerit.oy/', '_blank', 'noopener,noreferrer')}/>
            </a> 
            <a style={{marginRight: '2em;'}}>
              <FaLinkedinIn className="footerfab" onClick={() => window.open('https://www.linkedin.com/company/96452239/', '_blank', 'noopener,noreferrer')}/>
            </a>
          </div>
        </div>
        <div className='contact-section-left'>
          <h2 className="contact-heading">{t('CONTACT')}</h2>
          <p className='contact-description'>{t('CONTACT1')}</p>
          <div className='phonenumber-div'>
            <p className='phonenumber-text' style={{ display: 'inline-block', marginRight: '5px' }}>{t('CONTACT2')}</p>
            <a href="tel:+358403513954" className='phonenumber'>{t('CONTACT3')}</a>
          </div>
          <div className='some-section'>
            <h3 className='some-header'>{t("SOME")}</h3>
            <p className='some-description'>{t("SOME1")}</p>
              <div className="some-buttons">
              <a>
                <FaFacebookF className="footerfab"  onClick={() => window.open('https://www.facebook.com/profile.php?id=61551928770102', '_blank', 'noopener,noreferrer')}/>
              </a>
              <a>
                <FaInstagram className="footerfab" onClick={() => window.open('https://www.instagram.com/tinkerit.oy/', '_blank', 'noopener,noreferrer')}/>
              </a>
              <a>
                <FaLinkedinIn className="footerfab" onClick={() => window.open('https://www.linkedin.com/company/96452239/', '_blank', 'noopener,noreferrer')}/>
              </a>
            </div>
          </div>
        </div>
      </div>
      <form ref={form} onSubmit={sendEmail} className="form-section">
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {successMessage && <div className="success-message">{successMessage}</div>}
        <label style={{paddingTop: "20px"}}>{t('NAME')}</label>
        <input type="text" name="user_name" />
        <label>{t('EMAIL')}</label>
        <input type="email" name="user_email" />
        <label>{t('MESSAGE')}</label>
        <textarea name="message" onInput={adjustTextareaHeight} />
        <input type="submit" value={isSubmitted ? t('SENT') : t('SEND')} className={isSubmitted ? 'green-button' : ''} />
      </form>
      
    </div>
  );
};

export default ContactUs;
